import "react-dates/initialize";
import "react-phone-number-input/style.css";
import React, { FC, useState, useContext, useEffect, useCallback, useRef } from "react";
import { ThemeContext } from "styled-components";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import Checkbox from "../Checkbox";
import { FormInput, FormError, InputUnitWrapper, InfoIconContainer } from "../FormComponents";
import InfoIcon from "../../svgs/Legend";
import InfoTooltip from "../Tooltip";
import { exists, maxLength, min, validEmail, validNumber, validPhoneNumber, validWebhook } from "../../util/formValidations";
import { Select } from "../Select";
import { AsyncSelect, CreatableSelect } from "../Select";
import { fetchAutoComplete, fetchAutoCompleteTriggers } from "../../services/autoComplete";
import SingleDatePicker from "../SingleDatePicker";
import moment from "moment";
import { fetchAlerts, postAlerts } from "../../services/editAlerts";
import ScheduleModal from "../ScheduleModal";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import { printTempUnit, convertRoundedTemp, toCelsius } from "../../util/formatUnits";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../Buttons";
import { postTestAlert } from "../../services/testAlert";
import EditPlaceMap from "../EditPlaceMap";
import { toast } from "react-toastify";
import ExternalLink from "../../util/externalLink";
import addOrUpdate from "../../util/addOrUpdate";
import TrackerInfoPanel from "../TrackerInfoPanel";

const defaultAlert = {
  id: "",
  enabled: true,
  alertName: "",
  emailAddress: [],
  phoneNumber: [],
  webhook: [],
  loaded: false,
};

const defaultConditions = {
  schedules: [],
  startDate: null,
  endDate: null,
  tracker: null,
  place: null,
  trackerTag: null,
  assetId: null,
  assetType: null,
  placeTag: null,
  placeType: null,
  trackerType: null,
  contentTag: null,
  latitude: null,
  longitude: null,
  allManifests: null,
  manifestTracker: null,
  manifestPlace: null,
  radius: 200,
};

const defaultTriggers = {
  daysOffline: "",
  daysReturned: "",
  userScan: true,
  reboot: true,
  magnetWakeup: true,
  opened: true,
  lightWakeup: true,
  alertEnter: true,
  alertLeave: true,
  minTemperature: "",
  maxTemperature: "",
  temperatureDuration: "",
  minNetwork: "",
  maxNetwork: "",
  minLight: "",
  maxLight: "",
  minBattery: "",
  upright: true,
  upsideDown: true,
  moved: true,
  minInput1: "",
  maxInput1: "",
  minInput2: "",
  maxInput2: "",
  minInput3: "",
  maxInput3: "",
  minInput4: "",
  maxInput4: "",
  minInput5: "",
  maxInput5: "",
  minInput6: "",
  maxInput6: "",
  minInput7: "",
  maxInput7: "",
  minSupplyVoltage: "",
  maxSupplyVoltage: "",
};

const EditAlertModal: FC<any> = ({ id, setData, modalOpen, setModalOpen }) => {
  const autocompleteRef = useRef(null);

  const { color } = useContext(ThemeContext);

  const initFormData = (alert?: any) => {
    if (alert) {
      const formData = {
        ...defaultAlert,
        ...defaultConditions,
        ...defaultTriggers,
        ...alert,
        emailAddress: alert.emailAddress.map((email: string) => ({ value: email, label: email }), []),
        phoneNumber: alert.phoneNumber.map((number: string) => ({ value: number, label: number }), []),
        webhook: alert.webhook.map((webhook: string) => ({ value: webhook, label: webhook }), []),
        tracker: alert.sensorId ? { value: alert.sensorId, label: alert.nickname ? alert.sensorId + " (" + alert.nickname + ")" : alert.sensorId } : null,
        place: alert.placeId ? { value: alert.placeId, label: alert.placeName ? alert.placeName : alert.placeId } : null,
        trackerTag: alert.sensorGroupId ? { value: alert.sensorGroupId, label: alert.sensorGroup, colour: alert.sensorGroupColour } : null,
        assetId: alert.assetId ? { value: alert.assetId, label: alert.assetId } : null,
        assetType: alert.assetTypeId
          ? { value: alert.assetTypeId, label: alert.assetTypeName, colour: alert.assetTypeColour, icon: alert.assetTypeIcon }
          : null,
        placeTag: alert.placeTagId ? { value: alert.placeTagId, label: alert.placeTagName, colour: alert.placeTagColour } : null,
        placeType: alert.placeGroupId ? { value: alert.placeGroupId, label: alert.placeGroup, colour: alert.placeGroupColour } : null,
        trackerType: alert.productId ? { value: alert.productId, label: alert.productName ? alert.productName : alert.productId } : null,
        contentTag: alert.contentTagId ? { value: alert.contentTagId, label: alert.contentTagName, colour: alert.contentTagColour } : null,
        manifestPlace: alert.manifestPlaceId
          ? { value: alert.manifestPlaceId, label: alert.manifestPlaceName ? alert.manifestPlaceName : alert.manifestPlaceId }
          : null,
        manifestTracker: alert.manifestTrackerId ? { value: alert.manifestTrackerId, label: alert.manifestTrackerId } : null,
        allManifests: alert.allManifests,
        loaded: true,
      };

      formData.minTemperature = convertRoundedTemp(formData.minTemperature);
      formData.maxTemperature = convertRoundedTemp(formData.maxTemperature);
      setFormData(formData);
    } else {
      setFormData({ ...defaultAlert, ...defaultConditions, ...defaultTriggers, loaded: true });
    }
  };

  const defaultConditionOptions = [
    { value: "startDate", label: "Start Date" },
    { value: "endDate", label: "End Date" },
    { value: "schedules", label: "Schedule" },
    { value: "tracker", label: kegsOrTrackers("Keg", "Tracker") },
    { value: "place", label: "Place" },
    { value: "trackerTag", label: kegsOrTrackers("Keg Tag", "Tracker Tag") },
    { value: "assetId", label: "Asset ID" },
    { value: "assetType", label: "Asset Type" },
    { value: "placeTag", label: "Place Tag" },
    { value: "placeType", label: "Place Type" },
    { value: "location", label: "Location" },
    { value: "trackerType", label: "Tracker Type" },
    { value: "contentTag", label: "Content Tag" },
    { value: "allManifests", label: "All Manifests" },
    { value: "manifestPlace", label: "Place Manifest Created" },
    { value: "manifestTracker", label: "Tracker Assigned to Manifest" },
  ];

  const [formData, setFormData] = useState<any>({ ...defaultAlert, ...defaultConditions, ...defaultTriggers });
  const [formErrors, setFormErrors] = useState<any>({});

  const [alert, setAlert] = useState<any>({});
  const [alertErr, setAlertErr] = useState<string>("");
  const [alertLoading, setAlertLoading] = useState<boolean>(false);

  const [triggers, setTriggers] = useState<any>([]);
  const [conditions, setConditions] = useState<any>({ options: [], loaded: false });

  const [triggerOptions, setTriggerOptions] = useState<any>([]);
  const [triggerData, setTriggerData] = useState<any>({});
  const [triggersErr, setTriggersErr] = useState<string>("");
  const [triggersLoading, setTriggersLoading] = useState<boolean>(false);

  const [conditionOptions, setConditionOptions] = useState<any>(defaultConditionOptions);

  const [inputValues, setInputValues] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [testSuccess, setTestSuccess] = useState<boolean>(false);
  const [testLoading, setTestLoading] = useState<boolean>(false);

  const [scheduleModalOpen, setScheduleModalOpen] = useState<boolean>(false);

  const [triggersSet, setTriggersSet] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // if an id is provided we are editing an alert so fetch the data to populate the inputs
  // if no id then we are creating an alert so initialise the form data with the default data
  useEffect(() => {
    if (id) {
      setAlertLoading(true);
      fetchAlerts(source, id)
        .then((response) => {
          setAlert(response);
          initFormData(response);
          updateSelectedConditions(response);
          setAlertLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setAlertErr(errToStr(err));
            setAlertLoading(false);
          }
        });
    } else {
      initFormData();
      updateSelectedConditions();
    }
  }, [id, source]);

  // Fetch the trigger data when specific conditions change and only if the form data has initialised
  useEffect(() => {
    if (formData.loaded && conditions.loaded) {
      fetchTriggers();
    }
  }, [
    formData.loaded,
    conditions,
    formData.trackerTag,
    formData.trackerType,
    formData.tracker,
    formData.assetId,
    formData.assetType,
    formData.contentTag,
    formData.allManifests,
    formData.manifestPlace,
    formData.manifestTracker,
  ]);

  // When the triggerOptions change update the selected triggers
  useEffect(() => {
    if (formData.loaded && conditions.loaded && triggerOptions.length > 0 && !triggersSet) {
      setTriggers(
        triggerOptions.filter((trigger: any) => {
          if (Object.keys(alert).includes(trigger.value) && trigger.value !== "temperatureDuration") {
            return trigger;
          }
        }, [])
      );
      setTriggersSet(true);
    }
  }, [triggerOptions, conditions.loaded, formData.loaded]);

  // When the triggerOptions change update the selected triggers
  useEffect(() => {
    if (formData.loaded && conditions.loaded) {
      setTriggers((prev: any) =>
        triggerOptions.filter((trigger: any) => {
          if (Object.keys(formData).includes(trigger.value) && prev.some((t: any) => t.value === trigger.value) && trigger.value !== "temperatureDuration") {
            return trigger;
          }
        }, [])
      );
    }
  }, [triggerOptions, conditions, formData.loaded]);

  // Disabled due to https://www.notion.so/binarytech/Alert-Condition-Asset-Type-Tracker-Tag-s-c2a6e9dca4524382acda7a75ca2c4130?pvs=4
  // // Remove condition options when a specific option is selected to help filter which trigger options are available
  // useEffect(() => {
  //   if (conditions.loaded) {
  //     let newConditionOptions = JSON.parse(JSON.stringify(defaultConditionOptions));

  //     if (conditions.options.some((c: any) => c.value === "tracker")) {
  //       newConditionOptions = newConditionOptions.filter(
  //         (c: any) => c.value !== "trackerType" && c.value !== "trackerTag" && c.value !== "assetId" && c.value !== "assetType",
  //         []
  //       );
  //     } else if (conditions.options.some((c: any) => c.value === "trackerType")) {
  //       newConditionOptions = newConditionOptions.filter(
  //         (c: any) => c.value !== "tracker" && c.value !== "trackerTag" && c.value !== "assetId" && c.value !== "assetType",
  //         []
  //       );
  //     } else if (conditions.options.some((c: any) => c.value === "trackerTag")) {
  //       newConditionOptions = newConditionOptions.filter(
  //         (c: any) => c.value !== "trackerType" && c.value !== "tracker" && c.value !== "assetId" && c.value !== "assetType",
  //         []
  //       );
  //     } else if (conditions.options.some((c: any) => c.value === "assetId")) {
  //       newConditionOptions = newConditionOptions.filter(
  //         (c: any) => c.value !== "trackerType" && c.value !== "tracker" && c.value !== "trackerTag" && c.value !== "assetType",
  //         []
  //       );
  //     } else if (conditions.options.some((c: any) => c.value === "assetType")) {
  //       newConditionOptions = newConditionOptions.filter(
  //         (c: any) => c.value !== "trackerType" && c.value !== "tracker" && c.value !== "trackerTag" && c.value !== "assetId",
  //         []
  //       );
  //     }

  //     if (conditions.options.some((c: any) => c.value === "place")) {
  //       newConditionOptions = newConditionOptions.filter((c: any) => c.value !== "placeType" && c.value !== "location" && c.value !== "placeTag", []);
  //     } else if (conditions.options.some((c: any) => c.value === "placeType")) {
  //       newConditionOptions = newConditionOptions.filter((c: any) => c.value !== "place" && c.value !== "location" && c.value !== "placeTag", []);
  //     } else if (conditions.options.some((c: any) => c.value === "location")) {
  //       newConditionOptions = newConditionOptions.filter((c: any) => c.value !== "place" && c.value !== "placeType" && c.value !== "placeTag", []);
  //     } else if (conditions.options.some((c: any) => c.value === "placeTag")) {
  //       newConditionOptions = newConditionOptions.filter((c: any) => c.value !== "place" && c.value !== "placeType" && c.value !== "location", []);
  //     }

  //     setConditionOptions(newConditionOptions);
  //   }
  // }, [conditions]);

  // Ref callback that is triggered when the ref is updated
  // This updates the autocomplete attribute of Google's places autocomplete input
  // to "new-password" in order to hide the browser's autocomplete box in Chrome (Chrome bug).
  // https://stackoverflow.com/a/49161445/8629665
  const setAutocompleteRef = useCallback((node: any) => {
    if (node) {
      const observerHack = new MutationObserver(() => {
        observerHack.disconnect();
        node.autocomplete = "new-password";
      });

      observerHack.observe(node, {
        attributes: true,
        attributeFilter: ["autocomplete"],
      });
    }

    autocompleteRef.current = node;
  }, []);

  const fetchTriggers = () => {
    setTriggersLoading(true);

    const trackerTagId = formData.trackerTag && conditions.options.some((c: any) => c.value === "trackerTag") ? formData.trackerTag.value : null;
    const trackerTypeId = formData.trackerType && conditions.options.some((c: any) => c.value === "trackerType") ? formData.trackerType.value : null;
    const trackerId = formData.tracker && conditions.options.some((c: any) => c.value === "tracker") ? formData.tracker.value : null;
    const assetId = formData.assetId && conditions.options.some((c: any) => c.value === "assetId") ? formData.assetId.value : null;
    const assetTypeId = formData.assetType && conditions.options.some((c: any) => c.value === "assetType") ? formData.assetType.value : null;
    const contentTagId = formData.contentTag && conditions.options.some((c: any) => c.value === "contentTag") ? formData.contentTag.value : null;
    const geofence = conditions.options.some((c: any) => c.value === "place" || c.value === "placeTag" || c.value === "placeType" || c.value === "location");
    const allManifests = formData.allManifests && conditions.options.some((c: any) => c.value === "allManifests") ? formData.allManifests : false;
    const manifestPlaceId = formData.manifestPlace && conditions.options.some((c: any) => c.value === "manifestPlace") ? formData.manifestPlace.value : null;
    const manifestTrackerId =
      formData.manifestTracker && conditions.options.some((c: any) => c.value === "manifestTracker") ? formData.manifestTracker.value : null;

    fetchAutoCompleteTriggers(
      trackerTagId,
      trackerTypeId,
      trackerId,
      assetId,
      assetTypeId,
      contentTagId,
      geofence,
      allManifests,
      manifestTrackerId,
      manifestPlaceId
    )
      .then((response) => {
        setTriggerOptions(response);
        setTriggerData(
          response.reduce(
            (data: any, option: any) => ({
              ...data,
              [option.value]: option,
            }),
            {}
          )
        );
        setTriggersLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setTriggersErr(errToStr(err));
          setTriggersLoading(false);
        }
      });
  };

  const updateSelectedConditions = (alert?: any) => {
    setConditions({
      options: alert
        ? conditionOptions.filter((condition: any) => {
            if (Object.keys(alert).includes(condition.value)) {
              if (Array.isArray(alert[condition.value]) ? alert[condition.value].length > 0 : alert[condition.value]) {
                return condition;
              }
            } else if (condition.value === "tracker" && Object.keys(alert).includes("sensorId")) {
              return condition;
            } else if (condition.value === "place" && Object.keys(alert).includes("placeId")) {
              return condition;
            } else if (condition.value === "trackerTag" && Object.keys(alert).includes("sensorGroupId")) {
              return condition;
            } else if (condition.value === "assetId" && Object.keys(alert).includes("assetId")) {
              return condition;
            } else if (condition.value === "assetType" && Object.keys(alert).includes("assetTypeId")) {
              return condition;
            } else if (condition.value === "placeTag" && Object.keys(alert).includes("placeTagId")) {
              return condition;
            } else if (condition.value === "placeType" && Object.keys(alert).includes("placeGroupId")) {
              return condition;
            } else if (condition.value === "location" && Object.keys(alert).includes("latitude")) {
              return condition;
            } else if (condition.value === "trackerType" && Object.keys(alert).includes("productId")) {
              return condition;
            } else if (condition.value === "contentTag" && Object.keys(alert).includes("contentTagId")) {
              return condition;
            } else if (condition.value === "allManifests" && Object.keys(alert).includes("allManifests")) {
              return condition;
            } else if (condition.value === "manifestPlace" && Object.keys(alert).includes("manifestPlaceId")) {
              return condition;
            } else if (condition.value === "manifestTracker" && Object.keys(alert).includes("manifestTrackerId")) {
              return condition;
            }
          }, [])
        : [],
      loaded: true,
    });
  };

  // Used a hundred times below to set form errors on validation
  const setFieldValid = (name: string) => {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  };

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        // If there are any emails, check if they're all valid and if there is
        // currently text in the input but it's not a created item, prevent submission
        case "emailAddress":
          if ((value && value.length > 0) || inputValues[name]) {
            currValid = value.every((email: any) => !inputValues[name] && validEmail(name, email.value, setFormErrors));
          } else {
            currValid = true;
            setFormErrors((prev: any) => ({
              ...prev,
              [name]: false,
            }));
          }
          break;

        case "phoneNumber":
          // If there are any phone numbers, check if they're all valid and if there is
          // currently text in the input but it's not a created item, prevent submission
          if ((value && value.length > 0) || inputValues[name]) {
            currValid = !inputValues[name] && value.every((phone: any) => validPhoneNumber(name, phone.value, setFormErrors));
          } else {
            currValid = true;
            setFormErrors((prev: any) => ({
              ...prev,
              [name]: false,
            }));
          }
          break;

        case "webhook":
          // If there are any webhooks, check if they're all valid and if there is
          // currently text in the input but it's not a created item, prevent submission
          if ((value && value.length > 0) || inputValues[name]) {
            currValid =
              !inputValues[name] &&
              value.every((webhook: any) => validWebhook(name, webhook.value, setFormErrors) && maxLength(name, webhook.value, 200, setFormErrors));
          } else {
            currValid = true;
            setFormErrors((prev: any) => ({ ...prev, [name]: false }));
          }
          break;

        case "startDate":
          if (conditions.options.some((condition: any) => condition.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "endDate":
          if (conditions.options.some((condition: any) => condition.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "tracker":
          if (conditions.options.some((condition: any) => condition.value === name)) {
            currValid = exists(name, value ? value.value : null, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "place":
          if (conditions.options.some((condition: any) => condition.value === name)) {
            currValid = exists(name, value ? value.value : null, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "trackerTag":
          if (conditions.options.some((condition: any) => condition.value === name)) {
            currValid = exists(name, value ? value.value : null, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "assetId":
          if (conditions.options.some((condition: any) => condition.value === name)) {
            currValid = exists(name, value ? value.value : null, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "assetType":
          if (conditions.options.some((condition: any) => condition.value === name)) {
            currValid = exists(name, value ? value.value : null, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "placeTag":
          if (conditions.options.some((condition: any) => condition.value === name)) {
            currValid = exists(name, value ? value.value : null, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "placeType":
          if (conditions.options.some((condition: any) => condition.value === name)) {
            currValid = exists(name, value ? value.value : null, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "latitude":
          if (conditions.options.some((condition: any) => condition.value === "location")) {
            currValid = validNumber(name, value, setFormErrors);
          } else {
            currValid = true;
            setFormErrors((prev: any) => ({
              ...prev,
              [name]: false,
            }));
          }
          break;

        case "longitude":
          if (conditions.options.some((condition: any) => condition.value === "location")) {
            currValid = validNumber(name, value, setFormErrors);
          } else {
            currValid = true;
            setFormErrors((prev: any) => ({
              ...prev,
              [name]: false,
            }));
          }
          break;

        case "radius":
          if (conditions.options.some((condition: any) => condition.value === "location")) {
            currValid = validNumber(name, value, setFormErrors);
          } else {
            currValid = true;
            setFormErrors((prev: any) => ({
              ...prev,
              [name]: false,
            }));
          }
          break;

        case "trackerType":
          if (conditions.options.some((condition: any) => condition.value === name)) {
            currValid = exists(name, value ? value.value : null, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "contentTag":
          if (conditions.options.some((condition: any) => condition.value === name)) {
            currValid = exists(name, value ? value.value : null, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "daysOffline":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors) && min(name, value, 1, setFormErrors); // 1 day
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "daysReturned":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "userScan":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "minTemperature":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "maxTemperature":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "minNetwork":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "maxNetwork":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "minLight":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "maxLight":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "minBattery":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "minInput1":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "maxInput1":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "minInput2":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "maxInput2":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "minInput3":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "maxInput3":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "minInput4":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "maxInput4":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "minInput5":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "maxInput5":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "minInput6":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "maxInput6":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "minInput7":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "maxInput7":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "minSupplyVoltage":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        case "maxSupplyVoltage":
          if (triggers.some((trigger: any) => trigger.value === name)) {
            currValid = exists(name, value, setFormErrors);
          } else {
            currValid = setFieldValid(name);
          }
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    const formatted: any = {};

    formatted.id = formData.id ? formData.id : null;
    formatted.alertName = formData.alertName.trim();
    formatted.emailAddress = formData.emailAddress && formData.emailAddress.length > 0 ? formData.emailAddress.map((email: any) => email.value, []) : [];
    formatted.phoneNumber = formData.phoneNumber && formData.phoneNumber.length > 0 ? formData.phoneNumber.map((phone: any) => phone.value, []) : [];
    formatted.webhook = formData.webhook && formData.webhook.length > 0 ? formData.webhook.map((webhook: any) => webhook.value, []) : [];
    formatted.enabled = formData.enabled;
    formatted.schedules = formData.schedules;

    for (let i = 0; i < conditions.options.length; i++) {
      const condition = conditions.options[i].value;

      switch (condition) {
        case "tracker":
          if (formData.tracker && formData.tracker.value) {
            formatted.sensorId = formData.tracker.value;
          }
          break;

        case "place":
          if (formData.place && formData.place.value) {
            formatted.placeId = formData.place.value;
          }
          break;

        case "trackerTag":
          if (formData.trackerTag && formData.trackerTag.value) {
            formatted.SensorGroupId = formData.trackerTag.value;
          }
          break;

        case "assetId":
          if (formData.assetId && formData.assetId.value) {
            formatted.assetId = formData.assetId.value;
          }
          break;

        case "assetType":
          if (formData.assetType && formData.assetType.value) {
            formatted.assetTypeId = formData.assetType.value;
          }
          break;

        case "placeTag":
          if (formData.placeTag && formData.placeTag.value) {
            formatted.placeTagId = formData.placeTag.value;
          }
          break;

        case "placeType":
          if (formData.placeType && formData.placeType.value) {
            formatted.placeGroupId = formData.placeType.value;
          }
          break;

        case "location":
          formatted.latitude = formData.latitude;
          formatted.longitude = formData.longitude;
          formatted.radius = formData.radius;
          break;

        case "trackerType":
          if (formData.trackerType && formData.trackerType.value) {
            formatted.productId = formData.trackerType.value;
          }
          break;

        case "contentTag":
          if (formData.contentTag && formData.contentTag.value) {
            formatted.contentTagId = formData.contentTag.value;
          }
          break;

        case "manifestPlace":
          if (formData.manifestPlace && formData.manifestPlace.value) {
            formatted.manifestPlaceId = formData.manifestPlace.value;
          }
          break;

        case "manifestTracker":
          if (formData.manifestTracker && formData.manifestTracker.value) {
            formatted.manifestTrackerId = formData.manifestTracker.value;
          }
          break;

        default:
          formatted[condition] = formData[condition] ? formData[condition] : null;
          break;
      }
    }

    for (let i = 0; i < triggers.length; i++) {
      const trigger = triggers[i].value;
      if (trigger === "minTemperature" || trigger === "maxTemperature") {
        formatted[trigger] = formData[trigger] ? toCelsius(formData[trigger]) : null;
        formatted.temperatureDuration = formData.temperatureDuration ? +formData.temperatureDuration : null;
      } else {
        formatted[trigger] = formData[trigger] ? formData[trigger] : null;
      }
    }

    return formatted;
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      postAlerts(source, body)
        .then((response) => {
          setData((prev: any) => addOrUpdate(prev, response, "id"));
          setSubmittedMsg(formData.id ? "Alert Updated" : "Alert Created");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const sendTestAlert = () => {
    const body = {
      emailAddress: formData.emailAddress && formData.emailAddress.length > 0 ? formData.emailAddress.map((email: any) => email.value, []) : [],
      phoneNumber: formData.phoneNumber && formData.phoneNumber.length > 0 ? formData.phoneNumber.map((phone: any) => phone.value, []) : [],
      webhook: formData.webhook && formData.webhook.length > 0 ? formData.webhook.map((webhook: any) => webhook.value, []) : [],
    };
    const valid = validateForm();

    setTestSuccess(false);
    if (valid) {
      setTestLoading(true);
      postTestAlert(source, body)
        .then((response) => {
          setTestLoading(false);
          toast.info("Test Alert Sent!");
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setTestSuccess(false);
            setTestLoading(false);
          }
        });
    }
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const createMultiTextInput = (name: string, inputValue: any, value: any) => {
    let valid = true;

    if (name === "emailAddress") valid = validEmail(name, inputValue, setFormErrors);
    if (name === "webhook") valid = validWebhook(name, inputValue, setFormErrors) && maxLength(name, inputValue, 200, setFormErrors);
    if (name === "phoneNumber") valid = validPhoneNumber(name, inputValue, setFormErrors);

    if (formData[name].some((e: any) => e.value === inputValue)) {
      setFormErrors((prev: any) => ({
        ...prev,
        [name]: "Value already exists",
      }));
      valid = false;
    }

    if (valid) {
      setInputValues((prev: any) => ({ ...prev, [name]: "" }));
      setFormData((prev: any) => ({
        ...prev,
        [name]: [
          ...value,
          {
            label: inputValue,
            value: inputValue,
          },
        ],
      }));
    }
  };

  const handleMultiTextKeyDown = (e: any, name: string) => {
    const inputValue = inputValues[name];
    const value = formData[name];

    if (inputValue) {
      switch (e.key) {
        case "Enter":
        case "Tab":
        case ",":
        case ";":
          createMultiTextInput(name, inputValue, value);
          e.preventDefault();
      }
    }
  };

  const handleMultiTextBlur = (e: any, name: string) => {
    const inputValue = inputValues[name];
    const value = formData[name];

    if (inputValue) {
      createMultiTextInput(name, inputValue, value);
      e.preventDefault();
    }
  };

  const handleMultiTextInputChange = (value: any, action: any, name: string) => {
    if (action === "input-change") {
      setInputValues((prev: any) => ({
        ...prev,
        [name]: value,
      }));
      setFormErrors((prev: any) => ({
        ...prev,
        [name]: undefined,
      }));
    }
  };

  const handleMultiTextChange = (value: any, name: string) => {
    setFormData((prev: any) => ({
      ...prev,
      [name]: value ? value : [],
    }));
  };

  const handleDateChange = (date: any, inputName: string) => {
    setFormData((prev: any) => ({
      ...prev,
      [inputName]: date ? date.format("YYYY-MM-DD") : null,
    }));
    setFormErrors((prev: any) => ({ ...prev, [inputName]: undefined }));
  };

  const handleCheckboxChange = (e: any) => {
    e.persist();
    setFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  // Auto-populates select input on search.
  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  const handleClose = () => {
    if (!submitting) setModalOpen(false);
  };

  const getGeofenceTooltip = (geofenceType: string) => {
    return formData.alertEnter || formData.alertLeave
      ? `An alert will be sent if ${kegsOrTrackers("a keg", "a tracker")} ${
          formData.alertEnter && formData.alertLeave ? "enters or leaves" : formData.alertEnter ? "enters" : "leaves"
        } the specified ${geofenceType}`
      : `An alert will only be sent if ${kegsOrTrackers("a keg", "a tracker")} is triggered while at the specified ${geofenceType}`;
  };

  // Show loading modal when formData is undefined
  if (!formData) {
    return (
      <SubmitModal
        size="lg"
        isOpen={modalOpen}
        onClose={handleClose}
        title={id ? "Edit Alert" : "Create Alert"}
        error={alertErr}
        submitBtnText={id ? "Update Alert" : "Create Alert"}
        body={<LoadingContainer loading={alertLoading}></LoadingContainer>}
      />
    );
  }

  return (
    <>
      <SubmitModal
        isOpen={modalOpen}
        onClose={handleClose}
        size={!submittedMsg && !submittingErr ? "lg" : "sm"}
        title={formData.id ? "Edit Alert" : "Create Alert"}
        success={submittedMsg}
        error={alertErr || submittingErr}
        footer={
          submittedMsg ? (
            <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
          ) : submittingErr ? (
            <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
          ) : (
            <>
              <OutlineBtn style={{ marginRight: "auto" }} disabled={testLoading} onClick={sendTestAlert}>
                Test Alert
              </OutlineBtn>
              <GhostBtn onClick={handleClose}>Cancel</GhostBtn>
              <PrimaryBtn onClick={handleSubmit}>{formData.id ? "Update Alert" : "Create Alert"}</PrimaryBtn>
            </>
          )
        }
        body={
          <LoadingContainer loading={alertLoading || triggersLoading || testLoading || submitting}>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                Alerts are messages sent to email addresses, phone numbers, or webhook URLs when specific events occur. These events can be configured by adding
                triggers and conditions below.
              </ModalFormContainer>
              <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                <Checkbox style={{ display: "inline-block" }} name="enabled" label="Active" checked={formData.enabled} onChange={handleCheckboxChange} />
              </ModalFormContainer>
              <ModalFormContainer>
                <label>Alert Name</label>
                <FormInput
                  type="text"
                  name="alertName"
                  value={formData.alertName}
                  error={formErrors.alertName}
                  onChange={handleChange}
                  placeholder="Temperature exceeded threshold"
                />
                <FormError error={formErrors.alertName}>{formErrors.alertName}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label style={{ float: "left" }}>Email Addresses</label>
                <div style={{ cursor: "pointer" }}>
                  <InfoTooltip content="Press Enter after typing to add another" interactive={true} touch={true} appendTo={document.body}>
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </InfoTooltip>
                </div>
                <CreatableSelect
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  inputValue={inputValues.emailAddress}
                  isClearable={true}
                  isMulti={true}
                  menuIsOpen={false}
                  onChange={(value: any) => handleMultiTextChange(value, "emailAddress")}
                  onInputChange={(value: string, { action }: any) => handleMultiTextInputChange(value, action, "emailAddress")}
                  onBlur={(e: any) => handleMultiTextBlur(e, "emailAddress")}
                  onKeyDown={(e: any) => handleMultiTextKeyDown(e, "emailAddress")}
                  type="text"
                  name="emailAddress"
                  id="email"
                  value={formData.emailAddress}
                  error={formErrors.emailAddress}
                  placeholder="Type email address and press enter..."
                />

                <FormError error={formErrors.emailAddress}>{formErrors.emailAddress}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label style={{ float: "left" }}>Phone Numbers</label>
                <div style={{ cursor: "pointer" }}>
                  <InfoTooltip
                    content={
                      <>
                        <p>Press Enter after typing to add another</p>
                        Phone numbers must begin with a{" "}
                        <ExternalLink url="https://en.wikipedia.org/wiki/List_of_country_calling_codes" label="country calling code" /> e.g. +61
                      </>
                    }
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                  >
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </InfoTooltip>
                </div>
                <CreatableSelect
                  style={{ clear: "both" }}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  inputValue={inputValues.phoneNumber}
                  isClearable={true}
                  isMulti={true}
                  menuIsOpen={false}
                  onChange={(value: any) => handleMultiTextChange(value, "phoneNumber")}
                  onInputChange={(value: string, { action }: any) => handleMultiTextInputChange(value, action, "phoneNumber")}
                  onBlur={(e: any) => handleMultiTextBlur(e, "phoneNumber")}
                  onKeyDown={(e: any) => handleMultiTextKeyDown(e, "phoneNumber")}
                  type="text"
                  name="phoneNumber"
                  id="email"
                  value={formData.phoneNumber}
                  error={formErrors.phoneNumber}
                  placeholder="Type phone number and press enter..."
                />
                <FormError error={formErrors.phoneNumber}>{formErrors.phoneNumber}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label style={{ float: "left" }}>Webhooks</label>
                <div style={{ cursor: "pointer" }}>
                  <InfoTooltip content="Press Enter after typing to add another" interactive={true} touch={true} appendTo={document.body}>
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </InfoTooltip>
                </div>
                <CreatableSelect
                  style={{ clear: "both" }}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  inputValue={inputValues.webhook}
                  isClearable={true}
                  isMulti={true}
                  menuIsOpen={false}
                  onChange={(value: any) => handleMultiTextChange(value, "webhook")}
                  onInputChange={(value: string, { action }: any) => handleMultiTextInputChange(value, action, "webhook")}
                  onBlur={(e: any) => handleMultiTextBlur(e, "webhook")}
                  onKeyDown={(e: any) => handleMultiTextKeyDown(e, "webhook")}
                  type="text"
                  name="webhook"
                  id="email"
                  value={formData.webhook}
                  error={formErrors.webhook}
                  placeholder="Type webhook and press enter..."
                />
                <FormError error={formErrors.webhook}>{formErrors.webhook}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label style={{ float: "left" }}>Conditions</label>
                <div style={{ cursor: "pointer" }}>
                  <InfoTooltip
                    content={<>If an event occurs that triggers an alert it will only be sent if it passes all these conditions</>}
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                  >
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </InfoTooltip>
                </div>
                <Select
                  name="conditions"
                  isError={formErrors.conditions}
                  isMulti={true}
                  isSearchable={true}
                  value={conditions.options}
                  options={conditionOptions}
                  onChange={(values: any, action: any) => {
                    if (values !== null) {
                      setConditions({ options: values, loaded: true });
                    } else {
                      setConditions({ options: [], loaded: true });
                    }
                  }}
                />
                <FormError error={formErrors.conditions}>{formErrors.conditions}</FormError>
              </ModalFormContainer>
              {conditions.options.some((condition: any) => condition.value === "startDate") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Start Date</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content="An alert will only be sent if it is triggered after the selected start date"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <SingleDatePicker
                    block={true}
                    date={formData.startDate ? moment(formData.startDate) : null}
                    displayFormat="YYYY-MM-DD"
                    error={formErrors.startDate}
                    id="start-date-picker"
                    isOutsideRange={() => false}
                    numberOfMonths={1}
                    onDateChange={(date: any) => handleDateChange(date, "startDate")}
                    placeholder="YYYY-MM-DD"
                    showClearDate={true}
                    small={true}
                    withPortal={true}
                    style={{ clear: "both" }}
                  />
                  <FormError error={formErrors.startDate}>{formErrors.startDate}</FormError>
                </ModalFormContainer>
              )}
              {conditions.options.some((condition: any) => condition.value === "endDate") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>End Date</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content="An alert will only be sent if it is triggered before the selected end date"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <SingleDatePicker
                    block={true}
                    date={formData.endDate ? moment(formData.endDate) : null}
                    displayFormat="YYYY-MM-DD"
                    error={formErrors.endDate}
                    id="end-date-picker"
                    isOutsideRange={() => false}
                    numberOfMonths={1}
                    onDateChange={(date: any) => handleDateChange(date, "endDate")}
                    placeholder="YYYY-MM-DD"
                    showClearDate={true}
                    small={true}
                    withPortal={true}
                    style={{ clear: "both" }}
                  />
                  <FormError error={formErrors.endDate}>{formErrors.endDate}</FormError>
                </ModalFormContainer>
              )}
              {conditions.options.some((condition: any) => condition.value === "schedules") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Schedule</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content="An alert will only be sent if it is triggered during the defined schedule"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <FormInput
                    readOnly="readonly"
                    style={{ cursor: "pointer" }}
                    name="schedules"
                    value={formData.schedules.length === 0 ? "Add Schedule" : "Edit Schedule"}
                    onClick={() => setScheduleModalOpen(true)}
                  />
                  <FormError error={formErrors.schedules}>{formErrors.schedules}</FormError>
                </ModalFormContainer>
              )}
              {conditions.options.some((condition: any) => condition.value === "tracker") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>{kegsOrTrackers("Keg", "Tracker")}</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content={`An alert will only be sent if it is triggered by the selected ${kegsOrTrackers("keg", "tracker")}`}
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <AsyncSelect
                    name="tracker"
                    defaultOptions={true}
                    isClearable={true}
                    isError={formErrors.tracker}
                    value={formData.tracker}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("trackers", inputValue, callback)}
                    onChange={handleSelectChange}
                    placeholder="Select..."
                  />
                  <FormError error={formErrors.tracker}>{formErrors.tracker}</FormError>
                  <TrackerInfoPanel trackerId={formData.tracker?.value} />
                </ModalFormContainer>
              )}
              {conditions.options.some((condition: any) => condition.value === "place") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Place</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip content={getGeofenceTooltip("place")} interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <AsyncSelect
                    name="place"
                    defaultOptions={true}
                    isClearable={true}
                    isError={formErrors.place}
                    value={formData.place}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("places", inputValue, callback)}
                    onChange={handleSelectChange}
                    placeholder="Select..."
                  />
                  <FormError error={formErrors.place}>{formErrors.place}</FormError>
                </ModalFormContainer>
              )}
              {conditions.options.some((condition: any) => condition.value === "trackerTag") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>{kegsOrTrackers("Keg Tag", "Tracker Tag")}</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content={`An alert will only be sent if it is triggered by ${kegsOrTrackers(
                        "a keg",
                        "a tracker"
                      )} that is part of the selected ${kegsOrTrackers("keg", "tracker")} tag`}
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <AsyncSelect
                    name="trackerTag"
                    defaultOptions={true}
                    isClearable={true}
                    isError={formErrors.trackerTag}
                    value={formData.trackerTag}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("trackertags", inputValue, callback)}
                    onChange={handleSelectChange}
                    placeholder="Select..."
                  />
                  <FormError error={formErrors.trackerTag}>{formErrors.trackerTag}</FormError>
                </ModalFormContainer>
              )}
              {conditions.options.some((condition: any) => condition.value === "assetId") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Asset ID</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content="An alert will only be sent if it is triggered by the tracker with the selected Asset ID"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <AsyncSelect
                    name="assetId"
                    defaultOptions={true}
                    isClearable={true}
                    isError={formErrors.assetId}
                    value={formData.assetId}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("assetids", inputValue, callback)}
                    onChange={handleSelectChange}
                    placeholder="Select..."
                  />
                  <FormError error={formErrors.assetType}>{formErrors.assetType}</FormError>
                </ModalFormContainer>
              )}
              {conditions.options.some((condition: any) => condition.value === "assetType") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Asset Type</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content="An alert will only be sent if it is triggered by the selected Asset Type"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <AsyncSelect
                    name="assetType"
                    defaultOptions={true}
                    isClearable={true}
                    isError={formErrors.assetType}
                    value={formData.assetType}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("assettypes", inputValue, callback)}
                    onChange={handleSelectChange}
                    placeholder="Select..."
                  />
                  <FormError error={formErrors.assetType}>{formErrors.assetType}</FormError>
                </ModalFormContainer>
              )}
              {conditions.options.some((condition: any) => condition.value === "placeTag") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Place Tag</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip content={getGeofenceTooltip("place tag")} interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <AsyncSelect
                    name="placeTag"
                    defaultOptions={true}
                    isClearable={true}
                    isError={formErrors.placeTag}
                    value={formData.placeTag}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("placetags", inputValue, callback)}
                    onChange={handleSelectChange}
                    placeholder="Select..."
                  />
                  <FormError error={formErrors.placeTag}>{formErrors.placeTag}</FormError>
                </ModalFormContainer>
              )}
              {conditions.options.some((condition: any) => condition.value === "placeType") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Place Type</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip content={getGeofenceTooltip("place type")} interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <AsyncSelect
                    name="placeType"
                    defaultOptions={true}
                    isClearable={true}
                    isError={formErrors.placeType}
                    value={formData.placeType}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("placetypes", inputValue, callback)}
                    onChange={handleSelectChange}
                    placeholder="Select..."
                  />
                  <FormError error={formErrors.placeType}>{formErrors.placeType}</FormError>
                </ModalFormContainer>
              )}
              {conditions.options.some((condition: any) => condition.value === "location") && (
                <>
                  <ModalFormContainer>
                    <label style={{ float: "left" }}>Location</label>
                    <div style={{ cursor: "pointer" }}>
                      <InfoTooltip content={getGeofenceTooltip("location")} interactive={true} touch={true} appendTo={document.body}>
                        <InfoIconContainer>
                          <InfoIcon fill={color.font[2]} />
                        </InfoIconContainer>
                      </InfoTooltip>
                    </div>
                    <FormInput
                      type="text"
                      ref={setAutocompleteRef}
                      placeholder="Search..."
                      error={formData.placeNotFound}
                      autoComplete="new-password"
                      onKeyPress={(e: any) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                    />
                    <FormError error={formData.placeNotFound}>Place not found</FormError>
                  </ModalFormContainer>
                  <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                    <EditPlaceMap
                      place={formData}
                      setPlace={setFormData}
                      autocompleteRef={autocompleteRef}
                      error={formErrors.latitude || formErrors.longitude}
                    />
                    <FormError error={formErrors.latitude || formErrors.longitude}>Please select location on map</FormError>
                  </ModalFormContainer>
                </>
              )}
              {conditions.options.some((condition: any) => condition.value === "trackerType") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Tracker Type</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content="An alert will only be sent if it is triggered by the selected tracker type"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <AsyncSelect
                    name="trackerType"
                    defaultOptions={true}
                    isClearable={true}
                    isError={formErrors.trackerType}
                    value={formData.trackerType}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("trackertypes", inputValue, callback)}
                    onChange={handleSelectChange}
                    placeholder="Select..."
                  />
                  <FormError error={formErrors.trackerType}>{formErrors.trackerType}</FormError>
                </ModalFormContainer>
              )}
              {conditions.options.some((condition: any) => condition.value === "contentTag") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Content Tag</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content={`An alert will only be sent if it is triggered by ${kegsOrTrackers(
                        "a keg",
                        "a tracker"
                      )} that belongs to an active manifest that has contents with the selected content tag`}
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <AsyncSelect
                    name="contentTag"
                    defaultOptions={true}
                    isClearable={true}
                    isError={formErrors.contentTag}
                    value={formData.contentTag}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("contentTags", inputValue, callback)}
                    onChange={handleSelectChange}
                    placeholder="Select..."
                  />
                  <FormError error={formErrors.contentTag}>{formErrors.contentTag}</FormError>
                </ModalFormContainer>
              )}
              {conditions.options.some((condition: any) => condition.value === "allManifests") && (
                <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                  <Checkbox
                    style={{ display: "inline-block" }}
                    name="allManifests"
                    label="All Manifests"
                    checked={formData.allManifests}
                    onChange={handleCheckboxChange}
                  />
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginBottom: "-3px",
                    }}
                  >
                    <InfoTooltip
                      content={`An alert will only be sent if it is triggered by an active manifest`}
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                </ModalFormContainer>
              )}
              {conditions.options.some((condition: any) => condition.value === "manifestPlace") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Place Manifest Created</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content={`An alert will only be sent if it is triggered by an active manifest that was created at the selected place`}
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <AsyncSelect
                    name="manifestPlace"
                    defaultOptions={true}
                    isClearable={true}
                    isError={formErrors.manifestPlace}
                    value={formData.manifestPlace}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("places", inputValue, callback)}
                    onChange={handleSelectChange}
                    placeholder="Select..."
                  />
                  <FormError error={formErrors.manifestPlace}>{formErrors.manifestPlace}</FormError>
                </ModalFormContainer>
              )}
              {conditions.options.some((condition: any) => condition.value === "manifestTracker") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Tracker Assigned to Manifest</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content={`An alert will only be sent if it is triggered by the selected tracker that belongs to an active manifest`}
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <AsyncSelect
                    name="manifestTracker"
                    defaultOptions={true}
                    isClearable={true}
                    isError={formErrors.manifestTracker}
                    value={formData.manifestTracker}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("trackers", inputValue, callback)}
                    onChange={handleSelectChange}
                    placeholder="Select..."
                  />
                  <FormError error={formErrors.manifestTracker}>{formErrors.manifestTracker}</FormError>
                  <TrackerInfoPanel trackerId={formData.manifestTracker?.value} />
                </ModalFormContainer>
              )}
              <ModalFormContainer>
                <label style={{ float: "left" }}>Triggers</label>
                <div style={{ cursor: "pointer" }}>
                  <InfoTooltip content="If any of these events occur it triggers an alert" interactive={true} touch={true} appendTo={document.body}>
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </InfoTooltip>
                </div>
                <Select
                  name="triggers"
                  isError={formErrors.triggers}
                  isMulti={true}
                  isSearchable={true}
                  isLoading={triggersLoading}
                  value={triggers}
                  // filter out temperatureDuration trigger as we show this input automatically if the min/max temperature tigger is selected
                  options={triggerOptions.filter((option: any) => option.value !== "temperatureDuration")}
                  onChange={(values: any) => {
                    if (values !== null) {
                      setTriggers(values);
                    } else {
                      setTriggers([]);
                    }
                  }}
                />
                <FormError error={formErrors.triggers}>{formErrors.triggers}</FormError>
              </ModalFormContainer>
              {triggers.some((trigger: any) => trigger.value === "daysOffline") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Days Offline</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content={`If ${kegsOrTrackers("a keg", "a tracker")} hasn't been seen for this many days it will trigger an alert`}
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit="days">
                    <FormInput
                      type="number"
                      name="daysOffline"
                      value={formData.daysOffline}
                      error={formErrors.daysOffline}
                      onChange={handleChange}
                      min={1}
                      step={1}
                    />
                    <FormError error={formErrors.daysOffline}>{formErrors.daysOffline}</FormError>
                  </InputUnitWrapper>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "daysReturned") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Returned After Offline</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content={`If ${kegsOrTrackers("a keg", "a tracker")} returns from offline after this many days it will trigger an alert`}
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit="days">
                    <FormInput
                      type="number"
                      name="daysReturned"
                      value={formData.daysReturned}
                      error={formErrors.daysReturned}
                      onChange={handleChange}
                      min={1}
                      step={1}
                    />
                    <FormError error={formErrors.daysReturned}>{formErrors.daysReturned}</FormError>
                  </InputUnitWrapper>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "userScan") && (
                <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                  <Checkbox style={{ display: "inline-block" }} name="userScan" label="User Scan" checked={formData.userScan} onChange={handleCheckboxChange} />
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginBottom: "-3px",
                    }}
                  >
                    <InfoTooltip
                      content={`An alert will be triggered if a user scans ${kegsOrTrackers("a keg", "a tracker")}`}
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "reboot") && (
                <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                  <Checkbox style={{ display: "inline-block" }} name="reboot" label="Reboot" checked={formData.reboot} onChange={handleCheckboxChange} />
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginBottom: "-3px",
                    }}
                  >
                    <InfoTooltip content="If a tracker reboots it will trigger an alert" interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "magnetWakeup") && (
                <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                  <Checkbox
                    style={{ display: "inline-block" }}
                    name="magnetWakeup"
                    label="Magnet Wakeup"
                    checked={formData.magnetWakeup}
                    onChange={handleCheckboxChange}
                  />
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginBottom: "-3px",
                    }}
                  >
                    <InfoTooltip content="If a tracker is reset via magnet it will trigger an alert" interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "opened") && (
                <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                  <Checkbox style={{ display: "inline-block" }} name="opened" label="Opened" checked={formData.opened} onChange={handleCheckboxChange} />
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginBottom: "-3px",
                    }}
                  >
                    <InfoTooltip content="If a tracker's enclosure is opened it will trigger an alert" interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "lightWakeup") && (
                <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                  <Checkbox
                    style={{ display: "inline-block" }}
                    name="lightWakeup"
                    label="Light Wakeup"
                    checked={formData.lightWakeup}
                    onChange={handleCheckboxChange}
                  />
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginBottom: "-3px",
                    }}
                  >
                    <InfoTooltip
                      content="If a tracker is reset via light sensor it will trigger an alert"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "alertEnter") && (
                <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                  <Checkbox
                    style={{ display: "inline-block" }}
                    name="alertEnter"
                    label="Enter Geofence"
                    checked={formData.alertEnter}
                    onChange={handleCheckboxChange}
                  />
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginBottom: "-3px",
                    }}
                  >
                    <InfoTooltip
                      content={`An alert will be triggered if ${kegsOrTrackers("a keg", "a tracker")} enters the defined Geofence`}
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "alertLeave") && (
                <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                  <Checkbox
                    style={{ display: "inline-block" }}
                    name="alertLeave"
                    label="Leave Geofence"
                    checked={formData.alertLeave}
                    onChange={handleCheckboxChange}
                  />
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginBottom: "-3px",
                    }}
                  >
                    <InfoTooltip
                      content={`An alert will be triggered if ${kegsOrTrackers("a keg", "a tracker")} leaves the defined Geofence`}
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "minTemperature") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Temperature Less Than</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content="If the temperature measured by a tracker goes below this value it will trigger an alert"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit={printTempUnit()}>
                    <FormInput
                      type="number"
                      name="minTemperature"
                      value={formData.minTemperature}
                      error={formErrors.minTemperature}
                      onChange={handleChange}
                      step={0.1}
                    />
                    <FormError error={formErrors.minTemperature}>{formErrors.minTemperature}</FormError>
                  </InputUnitWrapper>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "maxTemperature") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Temperature Greater Than</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content="If the temperature measured by a tracker goes above this value it will trigger an alert"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit={printTempUnit()}>
                    <FormInput
                      type="number"
                      name="maxTemperature"
                      value={formData.maxTemperature}
                      error={formErrors.maxTemperature}
                      onChange={handleChange}
                      step={0.1}
                    />
                    <FormError error={formErrors.maxTemperature}>{formErrors.maxTemperature}</FormError>
                  </InputUnitWrapper>
                </ModalFormContainer>
              )}
              {triggerOptions.some((trigger: any) => trigger.value === "temperatureDuration") &&
                triggers.some((trigger: any) => trigger.value === "minTemperature" || trigger.value === "maxTemperature") && (
                  <ModalFormContainer>
                    <label style={{ float: "left" }}>Temperature Duration</label>
                    <div style={{ cursor: "pointer" }}>
                      <InfoTooltip
                        content="If the temperature measured by a tracker is out of range for longer than this duration it will trigger an alert. Use with Temperature Wakeup Duration for immediate notifications"
                        interactive={true}
                        touch={true}
                        appendTo={document.body}
                      >
                        <InfoIconContainer>
                          <InfoIcon fill={color.font[2]} />
                        </InfoIconContainer>
                      </InfoTooltip>
                    </div>
                    <InputUnitWrapper style={{ clear: "both" }} unit="minutes">
                      <FormInput
                        type="number"
                        name="temperatureDuration"
                        value={formData.temperatureDuration}
                        error={formErrors.temperatureDuration}
                        onChange={handleChange}
                        step={1}
                      />
                      <FormError error={formErrors.temperatureDuration}>{formErrors.temperatureDuration}</FormError>
                    </InputUnitWrapper>
                  </ModalFormContainer>
                )}
              {triggers.some((trigger: any) => trigger.value === "minNetwork") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Network Less Than</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content="If the network measured by a tracker goes below this value it will trigger an alert"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit="">
                    <FormInput type="number" name="minNetwork" value={formData.minNetwork} error={formErrors.minNetwork} onChange={handleChange} step={0.1} />
                    <FormError error={formErrors.minNetwork}>{formErrors.minNetwork}</FormError>
                  </InputUnitWrapper>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "maxNetwork") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Network Greater Than</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content="If the network measured by a tracker goes above this value it will trigger an alert"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit="">
                    <FormInput type="number" name="maxNetwork" value={formData.maxNetwork} error={formErrors.maxNetwork} onChange={handleChange} step={0.1} />
                    <FormError error={formErrors.maxNetwork}>{formErrors.maxNetwork}</FormError>
                  </InputUnitWrapper>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "minLight") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Light Less Than</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content="If the light level measured by a tracker goes below this value it will trigger an alert"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit="%">
                    <FormInput type="number" name="minLight" value={formData.minLight} error={formErrors.minLight} onChange={handleChange} step={0.1} />
                    <FormError error={formErrors.minLight}>{formErrors.minLight}</FormError>
                  </InputUnitWrapper>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "maxLight") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Light Greater Than</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content="If the light level measured by a tracker goes above this value it will trigger an alert"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit="%">
                    <FormInput type="number" name="maxLight" value={formData.maxLight} error={formErrors.maxLight} onChange={handleChange} step={0.1} />
                    <FormError error={formErrors.maxLight}>{formErrors.maxLight}</FormError>
                  </InputUnitWrapper>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "minBattery") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Battery Less Than</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content="If the battery level measured by a tracker goes below this value it will trigger an alert"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit="%">
                    <FormInput type="number" name="minBattery" value={formData.minBattery} error={formErrors.minBattery} onChange={handleChange} step={0.1} />
                    <FormError error={formErrors.minBattery}>{formErrors.minBattery}</FormError>
                  </InputUnitWrapper>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "upright") && (
                <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                  <Checkbox style={{ display: "inline-block" }} name="upright" label="Upright" checked={formData.upright} onChange={handleCheckboxChange} />
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginBottom: "-3px",
                    }}
                  >
                    <InfoTooltip
                      content="If a tracker's orientation changes to upright it will trigger an alert"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "upsideDown") && (
                <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                  <Checkbox
                    style={{ display: "inline-block" }}
                    name="upsideDown"
                    label="Upside Down"
                    checked={formData.upsideDown}
                    onChange={handleCheckboxChange}
                  />
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginBottom: "-3px",
                    }}
                  >
                    <InfoTooltip
                      content="If a tracker's orientation changes to upside down it will trigger an alert"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "moved") && (
                <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                  <Checkbox style={{ display: "inline-block" }} name="moved" label="Movement" checked={formData.moved} onChange={handleCheckboxChange} />
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginBottom: "-3px",
                    }}
                  >
                    <InfoTooltip content="If a tracker detects any movement it will trigger an alert" interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "minInput1") && triggerData.minInput1 && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>{triggerData.minInput1.label}</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip content={`If Input 1 goes below this value it will trigger an alert`} interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit={triggerData.minInput1.unit}>
                    <FormInput type="number" name="minInput1" value={formData.minInput1} error={formErrors.minInput1} onChange={handleChange} />
                  </InputUnitWrapper>
                  <FormError error={formErrors.minInput1}>{formErrors.minInput1}</FormError>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "maxInput1") && triggerData.maxInput1 && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>{triggerData.maxInput1.label}</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip content={`If Input 1 goes above this value it will trigger an alert`} interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit={triggerData.maxInput1.unit}>
                    <FormInput type="number" name="maxInput1" value={formData.maxInput1} error={formErrors.maxInput1} onChange={handleChange} />
                  </InputUnitWrapper>
                  <FormError error={formErrors.maxInput1}>{formErrors.maxInput1}</FormError>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "minInput2") && triggerData.minInput2 && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>{triggerData.minInput2.label}</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip content={`If Input 2 goes below this value it will trigger an alert`} interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit={triggerData.minInput2.unit}>
                    <FormInput type="number" name="minInput2" value={formData.minInput2} error={formErrors.minInput2} onChange={handleChange} />
                  </InputUnitWrapper>
                  <FormError error={formErrors.minInput2}>{formErrors.minInput2}</FormError>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "maxInput2") && triggerData.maxInput2 && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>{triggerData.maxInput2.label}</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip content={`If Input 2 goes above this value it will trigger an alert`} interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit={triggerData.maxInput2.unit}>
                    <FormInput type="number" name="maxInput2" value={formData.maxInput2} error={formErrors.maxInput2} onChange={handleChange} />
                  </InputUnitWrapper>
                  <FormError error={formErrors.maxInput2}>{formErrors.maxInput2}</FormError>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "minInput3") && triggerData.minInput3 && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>{triggerData.minInput3.label}</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip content={`If Input 3 goes below this value it will trigger an alert`} interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit={triggerData.minInput3.unit}>
                    <FormInput type="number" name="minInput3" value={formData.minInput3} error={formErrors.minInput3} onChange={handleChange} />
                  </InputUnitWrapper>
                  <FormError error={formErrors.minInput3}>{formErrors.minInput3}</FormError>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "maxInput3") && triggerData.maxInput3 && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>{triggerData.maxInput3.label}</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip content={`If Input 3 goes above this value it will trigger an alert`} interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit={triggerData.maxInput3.unit}>
                    <FormInput type="number" name="maxInput3" value={formData.maxInput3} error={formErrors.maxInput3} onChange={handleChange} />
                  </InputUnitWrapper>
                  <FormError error={formErrors.maxInput3}>{formErrors.maxInput3}</FormError>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "minInput4") && triggerData.minInput4 && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>{triggerData.minInput4.label}</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip content={`If Input 4 goes below this value it will trigger an alert`} interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit={triggerData.minInput4.unit}>
                    <FormInput type="number" name="minInput4" value={formData.minInput4} error={formErrors.minInput4} onChange={handleChange} />
                  </InputUnitWrapper>
                  <FormError error={formErrors.minInput4}>{formErrors.minInput4}</FormError>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "maxInput4") && triggerData.maxInput4 && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>{triggerData.maxInput4.label}</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip content={`If Input 4 goes above this value it will trigger an alert`} interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit={triggerData.maxInput4.unit}>
                    <FormInput type="number" name="maxInput4" value={formData.maxInput4} error={formErrors.maxInput4} onChange={handleChange} />
                  </InputUnitWrapper>
                  <FormError error={formErrors.maxInput4}>{formErrors.maxInput4}</FormError>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "minInput5") && triggerData.minInput5 && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>{triggerData.minInput5.label}</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip content={`If Input 5 goes below this value it will trigger an alert`} interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit={triggerData.minInput5.unit}>
                    <FormInput type="number" name="minInput5" value={formData.minInput5} error={formErrors.minInput5} onChange={handleChange} />
                  </InputUnitWrapper>
                  <FormError error={formErrors.minInput5}>{formErrors.minInput5}</FormError>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "maxInput5") && triggerData.maxInput5 && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>{triggerData.maxInput5.label}</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip content={`If Input 5 goes above this value it will trigger an alert`} interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit={triggerData.maxInput5.unit}>
                    <FormInput type="number" name="maxInput5" value={formData.maxInput5} error={formErrors.maxInput5} onChange={handleChange} />
                  </InputUnitWrapper>
                  <FormError error={formErrors.maxInput5}>{formErrors.maxInput5}</FormError>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "minInput6") && triggerData.minInput6 && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>{triggerData.minInput6.label}</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip content={`If Input 6 goes below this value it will trigger an alert`} interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit={triggerData.minInput6.unit}>
                    <FormInput type="number" name="minInput6" value={formData.minInput6} error={formErrors.minInput6} onChange={handleChange} />
                  </InputUnitWrapper>
                  <FormError error={formErrors.minInput6}>{formErrors.minInput6}</FormError>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "maxInput6") && triggerData.maxInput6 && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>{triggerData.maxInput6.label}</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip content={`If Input 6 goes above this value it will trigger an alert`} interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit={triggerData.maxInput6.unit}>
                    <FormInput type="number" name="maxInput6" value={formData.maxInput6} error={formErrors.maxInput6} onChange={handleChange} />
                  </InputUnitWrapper>
                  <FormError error={formErrors.maxInput6}>{formErrors.maxInput6}</FormError>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "minInput7") && triggerData.minInput7 && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>{triggerData.minInput7.label}</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip content={`If Input 7 goes below this value it will trigger an alert`} interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit={triggerData.minInput7.unit}>
                    <FormInput type="number" name="minInput7" value={formData.minInput7} error={formErrors.minInput7} onChange={handleChange} />
                  </InputUnitWrapper>
                  <FormError error={formErrors.minInput7}>{formErrors.minInput7}</FormError>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "maxInput7") && triggerData.maxInput7 && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>{triggerData.maxInput7.label}</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip content={`If Input 7 goes above this value it will trigger an alert`} interactive={true} touch={true} appendTo={document.body}>
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit={triggerData.maxInput7.unit}>
                    <FormInput type="number" name="maxInput7" value={formData.maxInput7} error={formErrors.maxInput7} onChange={handleChange} />
                  </InputUnitWrapper>
                  <FormError error={formErrors.maxInput7}>{formErrors.maxInput7}</FormError>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "minSupplyVoltage") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Supply Voltage Less Than</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content="If the supply voltage measured by a tracker goes below this value it will trigger an alert"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit="V">
                    <FormInput
                      type="number"
                      name="minSupplyVoltage"
                      value={formData.minSupplyVoltage}
                      error={formErrors.minSupplyVoltage}
                      onChange={handleChange}
                      step={0.1}
                    />
                    <FormError error={formErrors.minSupplyVoltage}>{formErrors.minSupplyVoltage}</FormError>
                  </InputUnitWrapper>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "maxSupplyVoltage") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Supply Voltage Greater Than</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content="If the supply voltage measured by a tracker goes above this value it will trigger an alert"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <InputUnitWrapper style={{ clear: "both" }} unit="V">
                    <FormInput
                      type="number"
                      name="maxSupplyVoltage"
                      value={formData.maxSupplyVoltage}
                      error={formErrors.maxSupplyVoltage}
                      onChange={handleChange}
                      step={0.1}
                    />
                    <FormError error={formErrors.maxSupplyVoltage}>{formErrors.maxSupplyVoltage}</FormError>
                  </InputUnitWrapper>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "manifestStateChange") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Manifest State Change</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content="If the manifest state changes it will trigger an alert (e.g. if a manifest goes from Created to Started)"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <Select
                    name="manifestStateChange"
                    isError={formErrors.conditions}
                    isMulti={false}
                    isSearchable={true}
                    value={formData.manifestStateChange}
                    options={[
                      { value: "any", label: "Any" },
                      { value: "created", label: "Created" },
                      { value: "started", label: "Started" },
                      { value: "finished", label: "Finished" },
                    ]}
                    onChange={handleSelectChange}
                    placeholder="Select..."
                  />
                  <FormError error={formErrors.manifestStateChange}>{formErrors.manifestStateChange}</FormError>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "manifestStatusChange") && (
                <ModalFormContainer>
                  <label style={{ float: "left" }}>Manifest State Change</label>
                  <div style={{ cursor: "pointer" }}>
                    <InfoTooltip
                      content="If the manifest status changes it will trigger an alert (e.g. if a manifest goes from OK to ALERT)"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                  <Select
                    name="manifestStatusChange"
                    isError={formErrors.conditions}
                    isMulti={false}
                    isSearchable={true}
                    value={formData.manifestStatusChange}
                    options={[
                      { value: "OK", label: "OK" },
                      { value: "ALERT", label: "ALERT" },
                    ]}
                    onChange={handleSelectChange}
                    placeholder="Select..."
                  />
                  <FormError error={formErrors.manifestStatusChange}>{formErrors.manifestStatusChange}</FormError>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "manifestMissedDeadline") && (
                <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                  <Checkbox
                    style={{ display: "inline-block" }}
                    name="manifestMissedDeadline"
                    label="Manifest Missed Deadline"
                    checked={formData.manifestMissedDeadline}
                    onChange={handleCheckboxChange}
                  />
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginBottom: "-3px",
                    }}
                  >
                    <InfoTooltip
                      content={`If the manifest misses it's deadline it will trigger an alert`}
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                </ModalFormContainer>
              )}
              {triggers.some((trigger: any) => trigger.value === "manifestTrackerAlert") && (
                <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                  <Checkbox
                    style={{ display: "inline-block" }}
                    name="manifestTrackerAlert"
                    label="Manifest Tracker Alert"
                    checked={formData.manifestTrackerAlert}
                    onChange={handleCheckboxChange}
                  />
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginBottom: "-3px",
                    }}
                  >
                    <InfoTooltip
                      content={`If the tracker assigned to the manifest has an alert, this will trigger an alert`}
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </InfoTooltip>
                  </div>
                </ModalFormContainer>
              )}
            </form>
          </LoadingContainer>
        }
      />
      {scheduleModalOpen && <ScheduleModal modalOpen={scheduleModalOpen} setModalOpen={setScheduleModalOpen} data={formData} setData={setFormData} />}
    </>
  );
};

export default EditAlertModal;
